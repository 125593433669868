<template>
    <div>
        <a-spin size="large" :spinning="spinning">
            <a-page-header title="返回" @back="goBack" />
            <a-tabs default-active-key="1">
                <a-tab-pane key="1" tab="批量删除员工">
                    <a-button type="primary" class="btn" @click="isShowExport = true"
                        style="margin-right: 10px;">新增批量删除</a-button>
                    <a-button type="primary" @click="getdeletelist">刷新列表</a-button>
                    <a-table :pagination="false" :columns="columns" :data-source="staffInfo"
                        :rowKey="(record, index) => record.id">
                        >
                        <template slot="vaccination" slot-scope="text">
                            <span v-if="text === true"> 是 </span>
                            <span v-else> 否 </span>
                        </template>
                        <template slot="operation" slot-scope="text, record">
                            <div>
                                <span style="color:#dddddd;" v-if="record.status === 0">
                                    <!-- 下载删除结果 -->
                                </span>
                                <span @click="downUploadResult(record.id)" style="color:#1890ff;cursor: pointer;"
                                    v-if="record.status === 1">
                                    下载删除结果
                                </span>
                                <span @click="downUploadResult(record.id)" style="color:#1890ff;cursor: pointer;"
                                    v-if="record.status === 2">
                                    下载删除结果
                                </span>
                            </div>
                        </template>
                    </a-table>
                    <div class="pageClass" v-if="staffInfo.length > 0">
                        <a-pagination :show-total="(total) => `共 ${total} 条`"
                            :pageSizeOptions="['10', '20', '30', '50', '100']" show-size-changer
                            @showSizeChange="showSizeChange" show-quick-jumper :total="staffTital" :defaultPageSize="10"
                            @change="onChange" :current="page" />
                        <br />
                    </div>
                </a-tab-pane>
            </a-tabs>
            <!-- 批量删除员工 -->
            <a-modal v-model="isShowExport" title="批量删除员工" :dialogStyle="{ top: '300px' }" :maskClosable="false"
                width="700px">
                <template slot="footer">
                    <a-button key="submit" type="primary" :loading="loading" :disabled="!fileName" @click="batchExport">
                        确认
                    </a-button>
                </template>
                <div>
                    <p>
                        <a-button type="primary" @click="downTemplate">点击下载文件模板</a-button>
                    </p>
                    <div class="batch">
                        <p>
                            1、下载文件模板，模板中内容包括：员工姓名、手机号、所属企业
                        </p>
                        <p>
                            2.在Excel中按照要求填写内容，请保证内容准确性，若姓名与手机号不匹配将会无法进行删除；
                        </p>
                        <p>3.点击下方的上传员工清单，系统将自动删除员工；</p>
                    </div>
                    <div>
                        上传附件:
                        <a-upload :show-file-list="false" :custom-request="uploadSectionFile" :disabled="disabled"
                            :file-list="fileList" :beforeUpload="checkFileType" accept=".xls,.xlsx">
                            <el-button v-if="!isUpload"><a-icon type="upload" /> 上传员工清单
                            </el-button>
                            <el-button v-if="isUpload"><a-icon type="upload" /> 重新上传
                            </el-button>
                        </a-upload>
                        <p class="batchInfo">
                            支持扩展名：xls、xlsx格式文件
                        </p>
                        <p class="batchInfoName" style="color:#1890ff;" v-if="isUploading || isUpload">
                            《{{ fileName }}》
                        </p>

                        <p>
                            <!-- <img v-if="isUploading" class="img" src="../../assets/img/企业员工批量导入.gif" alt="" /> -->
                        </p>
                    </div>
                </div>
            </a-modal>
        </a-spin>
    </div>
</template>
<script>
import * as api from '@/api/enterprise/enterprise'
export default {
    data() {
        return {
            spinning: false,
            columns: [
                {
                    title: '删除时间',
                    width: 200,
                    dataIndex: 'create_time',
                    key: 'create_time',
                },
                {
                    title: '删除成功人数',
                    dataIndex: 'success_count',
                    key: 'success_count',
                    width: 120,
                    align: 'center',
                    customRender: (record, text) => {
                        if (text.status === 0) {
                            return '-'
                        } else {
                            return record
                        }


                    }
                },
                {
                    title: '删除失败人数',
                    width: 100,
                    dataIndex: 'fail_count',
                    key: 'fail_count',
                    align: 'center',
                    customRender: (record, text) => {
                        if (text.status === 0) {
                            return '-'
                        } else {
                            return record
                        }


                    }
                },
                {
                    title: '操作人',
                    width: 110,
                    dataIndex: 'create_user_name',
                    key: 'create_user_name',
                },
                {
                    title: '操作状态',
                    width: 110,
                    dataIndex: 'status',
                    key: 'status',
                    customRender: (text, index) => {
                        if (text === 0) {
                            return "删除中";
                        } else if (text === 1) {
                            return "已完成，部分删除失败";
                        } else if (text === 2) {
                            return "已完成";
                        } else if (text === 3) {
                            return "删除失败";
                        }
                    },
                },
                {
                    title: '操作',
                    dataIndex: 'operation',
                    width: 170,
                    align: 'center',
                    scopedSlots: { customRender: 'operation' }
                }
            ],
            staffInfo: [],
            // 需求的总条数
            staffTital: 0,
            page: 1,
            size: 10,
            isShowExport: false,
            companyId: '', // 公司id
            companyName: '', // 所属公司
            // 是否正在上传中
            isUploading: false,
            disabled: false,
            failData: null,
            isSuccess: false,
            fileList: [],
            isUpload: false,
            fileObj: {},
            fileName: '',
            ISBTN: false,
            loading: false
        }

    },
    created() {
        this.getdeletelist()
        this.companyName = this.$store.getters.activeCompany.enterpriseName
        this.companyId = this.$store.getters.activeCompany.id
    },
    methods: {
        // 返回
        goBack() {
            // 针对 path === look 时 返回到 企业列表-人员列表
            if (this.$route.query.from === "look") {
                this.$router.push({ name: "look", query: { id: this.$route.query.enterprise_id } });
            } else {
                this.$router.push({
                    path: "/enterprise/userList",
                    params: {
                        type: "del",
                    },
                });
            }
        },
        getdeletelist() {
            let id =
                api.getdeletelist(this.page, this.size).then(res => {
                    console.log(res, '+++++++++++++');
                    this.staffInfo = res.data.data.rows
                    this.staffTital = res.data.data.total
                })
        },
        // 分页
        onChange(pageNumber) {
            this.page = pageNumber
            this.getdeletelist()
        },
        // 分页
        showSizeChange(current, pageSize) {
            this.page = 1
            this.size = pageSize
            this.getdeletelist()
        },
        // 下载模板
        async downTemplate() {
            try {
                let data = new FormData()
                data.append('enterpriseName', this.companyName)
                data.append('sourceType', 1)
                const res = await api.templateDeleteExport(data)
                if ('msSaveOrOpenBlob' in navigator) {
                    const url = res.data
                    const blob = new Blob([url], { type: 'application/vnd.ms-excel' })
                    let name = res.headers['content-disposition'].split('=')[1]
                    window.navigator.msSaveOrOpenBlob(blob, decodeURI(name))
                    return
                } else {
                    const url = window.URL.createObjectURL(new Blob([res.data]))
                    const link = document.createElement('a')
                    link.href = url
                    let name = res.headers['content-disposition'].split('=')[1]
                    link.setAttribute('download', decodeURI(name))
                    document.body.appendChild(link)
                    link.click()
                }
            } catch (error) {
                console.log(error)
                this.$message.error('下载模板失败')
            }
        },
        // 下载删除结果
        async downUploadResult(id) {
            try {
                const form = new FormData()
                form.append('id', id)
                form.append('enterpriseName', this.companyName)
                form.append('sourceType', 1)
                const res = await api.downUploadResult(form)
                if ('msSaveOrOpenBlob' in navigator) {
                    const url = res.data
                    const blob = new Blob([url], { type: 'application/vnd.ms-excel' })
                    let name = res.headers['content-disposition'].split('=')[1]
                    window.navigator.msSaveOrOpenBlob(blob, decodeURI(name))
                    return
                } else {
                    const url = window.URL.createObjectURL(new Blob([res.data]))
                    const link = document.createElement('a')
                    link.href = url
                    let name = res.headers['content-disposition'].split('=')[1]
                    link.setAttribute('download', decodeURI(name))
                    document.body.appendChild(link)
                    link.click()
                }
            } catch (error) {
                console.log(error)
                this.$message.error('下载模板失败')
            }
        },
        checkFileType(file) {
            if (
                file.type === 'application/vnd.ms-excel' ||
                file.type ===
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ) {
                return true
            }
            this.$message.error('只支持扩展名：xls、xlsx格式文件')
            return false
        },
        // 点击确认
        batchExport() {
            if (this.ISBTN) return
            this.ISBTN = true
            this.loading = true
            this.isUploading = true
            this.disabled = false
            this.isSuccess = false
            // this.isUpload = false
            const enterpriseId = this.$store.getters.activeCompany.id
            const form = new FormData()
            form.append('file', this.fileObj.file)
            api.batchAll(enterpriseId, form)
                .then(res => {
                    this.ISBTN = false
                    this.loading = false
                    this.fileObj = {}
                    this.fileName = ''
                    this.fileList = []
                    this.isShowExport = false
                    this.disabled = false
                    this.isUploading = false
                    this.isUpload = false
                    this.getdeletelist()
                })
                .catch(error => {
                    this.ISBTN = false
                    this.loading = false
                    this.fileList = []
                    this.fileObj = {}
                    this.fileName = ''
                    this.isShowExport = !this.isShowExport
                    this.isUploading = !this.isUploading
                    this.$message.error(error.response.data.error)
                    this.getdeletelist()
                    this.isSuccess = false
                    this.disabled = false
                    this.isUploading = false
                    this.isUpload = false
                })

        },
        // 批量删除
        uploadSectionFile(fileObj) {
            this.isUploading = false
            this.disabled = true
            this.isSuccess = false
            this.isUpload = true
            this.fileObj = fileObj;
            this.fileName = fileObj.file.name
            console.log(fileObj);
        },
        // 批量删除
        uploadSectionFile(fileObj) {
            this.disabled = false
            this.isSuccess = false
            this.isUpload = true
            this.fileObj = fileObj;
            this.fileName = fileObj.file.name
        },
    }
}
</script>
<style scoped lang="scss">
.pageClass {
    height: 50px;
    display: flex;
    justify-content: end;
    align-items: center;
}
</style>